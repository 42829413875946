<style>
#recent-verification {
  height: auto;
}
.upload-file-field.disabled input {
  z-index: 1;
  position: relative;
}
.table.dataTable.no-footer th:before {
  color: transparent;
  opacity: 1;
  height: 6px;
  width: 6px;
  background: transparent;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
}

.table.dataTable.no-footer th:after {
  color: transparent;
  opacity: 1;
  height: 6px;
  width: 6px;
  background: transparent;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
}


.verification-modal .form-field-group .form-label {
    min-width:0;
    max-width: 173px;
    flex: 0 0 173px;
}

.verification-modal .form-field-group .form-label + div {
    flex: 0 0 calc(100% - 173px);
    max-width: calc(100% - 173px);
}

.upload-wrap .uploaded-doc {
    margin-top: 12px;
    counter-reset: css-counter 0;
}

.upload-wrap .uploaded-docoments {
    counter-increment: css-counter 1;
    position: relative;
    padding-left: 20px;
}

.upload-wrap .uploaded-docoments + .uploaded-docoments {
  margin-top:12px;
}
.upload-wrap .uploaded-docoments:after {
    content: counter(css-counter) ". ";
    position: absolute;
    left: 0;
    font-weight: 500;
    top:4px;
}


.uploaded-docoments .underline-link {
    word-break: break-all;
    line-height: 22px;
}
.upload-file-field .form-control {
    background: #ffffff !important;
}

.verification-modal .form-field-group .form-label + div{
  flex: 0 0 calc(100% - 173px);
    max-width: calc(100% - 173px);
}
</style>
<template>
  <div class="profile-data-wrapper test" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0 &&
        userPermissions.profile_verification.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <h2 class="section-heading">RECENT VERIFICATIONS</h2>
          </div>
          <div class="col">
            <a
              href="javascript:void(0);"
              class="secondary-btn"
              @click="openSideModal('createVerification')"
              v-if="userPermissions.profile_verification.includes('Add')"
              >Add new Record</a
            >
            <button
              class="primary-btn"
              v-if="recentVerifications.length"
              type="button"
              @click="downloadRecentVerificationCsv()"
            >
              <i class="fas fa-download"></i>Download All
            </button>
          </div>
          <!-- End div center   -->
        </div>
      </div>
      <div class="custom-table no-arrows">
        <table class="table" id="recent-verification">
          <thead>
            <tr>
              <th>Date</th>
              <th class="no-sort">Profile</th>
              <th class="no-sort" >Uploaded Files</th>
              <th class="no-sort">Person Responsible</th>
              <th class="no-sort">Upwork Comments</th>
              <th class="no-sort">Closing Comments</th>
              <th class="no-sort">Failure Reason</th>
              <th class="no-sort">Verification Request Date</th>
              <th class="no-sort">Verification Closing Date</th>
              <th>Status</th>
              <th class="no-sort">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in recentVerifications" :key="data.Id">
              <td>{{ $filters.dateFormat(data.created_at, "DD/MM/YYYY") }}</td>
              <td>{{ data.uproster_profile.ProfileName }}</td>
              <td>
                <template v-if="data.documents.length > 0">
                  <ul class="verification-list">
                    <li v-for="document in data.documents" :key="document.id">
                      <a
                        :href="`${uploadPath + document.Documents}`"
                        class="underline-link"
                        target="_blank"
                        >
                        <font-awesome-icon :icon="['fas',getFileTypeIcons(document.DocumentName.split('.').pop().toLowerCase())]" /> {{ document.DocumentName }}</a
                      >
                    </li>
                  </ul>
                </template>
                <template v-else>
                  N/A
                </template>
              </td>
              <td>{{ data.b_d_team_profile.Name }}</td>
              <td>{{ (data.UpworksComment && data.UpworksComment.length > 95 ) ? data.UpworksComment.substring(0,95)+"..." : (data.UpworksComment) ? data.UpworksComment : 'N/A' }}</td>
              <td>{{ (data.ClosingComment && data.ClosingComment.length > 95 ) ? data.ClosingComment.substring(0,95)+"..." : (data.ClosingComment) ?  data.ClosingComment : 'N/A' }}</td>
              <td>{{ (data.FailureComment && data.FailureComment.length > 95 ) ? data.FailureComment.substring(0,95)+"..." : (data.FailureComment) ?  data.FailureComment : 'N/A' }}</td>
              <td>{{  (data.requestDate ) ? $filters.dateFormat(data.requestDate, "DD/MM/YYYY") : 'N/A' }}</td>
              <td>{{ (data.updated_at && data.Status !== 'inProgress') ? $filters.dateFormat(data.updated_at, "DD/MM/YYYY") : 'N/A'}}</td>
              <td>
                <template v-if="data.Status === 'failure'">Failure</template>
                <template v-if="data.Status === 'verified'">Verified</template>
                <template v-if="data.Status === 'inProgress'">In-Progress</template>
              </td>
              <td>
                <div class="table-action">
                  <ul class="d-flex">
                    <li
                      v-if="
                        ['verified', 'failure'].includes(data.Status) &&
                        userPermissions.profile_verification.includes('View')
                      "
                    >
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`View`"
                        @click="openSideModal('viewVerification', data.Id)"
                        ><span class="lnr lnr-eye"></span
                      ></a>
                    </li>
                    <li v-if="
                        userPermissions.profile_verification.includes('View')">
                          <router-link
                              class="custom-tooltip"
                              v-tippy="`View Verification History`"
                              :to="{
                                name: 'ProfileVerificationHistory',
                                params: { ID: data.UprosterProfileId },
                              }"
                              target="_blank"
                            >
                        <span class="lnr lnr-history"></span>
                      </router-link>
                    </li>  
                    <li
                      v-if="
                        !['verified', 'failure'].includes(data.Status) &&
                        userPermissions.profile_verification.includes('Update')
                      "
                    >
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`Edit`"
                        @click="openSideModal('editVerification', data.Id)"
                        ><span class="lnr lnr-pencil"></span
                      ></a>
                    </li>
                    <li v-if="userPermissions.profile_verification.includes('Delete')">
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`Delete`"
                        @click.prevent="deleteRecentVerification(data.Id)"
                        ><span class="lnr lnr-trash"></span
                      ></a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <h2 class="section-heading">BUSINESS TEAM STATS</h2>
          </div>
          <div class="col">
            <button
              class="primary-btn"
              v-if="verificationStatus.length"
              type="button"
              @click="downloadVerificationStatusCsv()"
            >
              <i class="fas fa-download"></i>Download All
            </button>
          </div>
          <!-- End div center   -->
        </div>
      </div>
      <div class="custom-table no-arrows">
        <table class="table" id="verification-status">
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Total Profiles Handled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in verificationStatus" :key="data.Id">
              <td>{{ data.Name }}</td>
              <td>{{ data.Role }}</td>
              <td>{{ data.verificationHandledCount }}</td>
              <td>
                <div class="table-action">
                  <ul class="d-flex">
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        @click.prevent="openSideModal('viewStats', data.Id)"
                      >
                        <span class="lnr lnr-eye" v-tippy="`View`"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="[
          'sm-side--modal',
          { 'verification-modal': sideModals.addEdit.show },
        ]"
      >
        <template v-slot:header>
          <template v-if="sideModals.newRecord.show">CREATE A NEW RECORD</template>
          <!-- <template v-if="sideModals.addEdit.show">
                  VERIFICATION DASHBOARD 
                  (<template v-if="verification.fields">EDIT</template><template v-else>ADD</template>)
              </template> -->
          <template v-if="sideModals.viewStats.show">
            {{ viewStats.BDTeamProfileName }} Verification Status
          </template>
        </template>
        <template v-slot:body>
          <div class="new-record--wrap text-center" v-if="sideModals.newRecord.show">
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              @click="openSideModal('createVerification')"
              >create a new record manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              @click.prevent="openSideModal('importVerification')"
              >import data</a
            >
          </div>
          <!-- <add-edit-verification v-if="sideModals.addEdit.show" :fields="verification.fields" @close="closeSideModal" @created="getData()"></add-edit-verification> -->
          <view-verification-status
            v-if="sideModals.viewStats.show"
            :data="viewStats.data"
          ></view-verification-status>
        </template>
      </SideModal>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="[
          'sm-side--modal',
          { 'verification-modal': sideModals.addEdit.show },
        ]"
        v-show="sideModals.addEdit.show || sideModals.import.show || sideModals.view.show"
      >
        <template v-slot:header>
          <template v-if="sideModals.addEdit.show">
            VERIFICATION DASHBOARD (<template v-if="verification.fields">EDIT</template
            ><template v-else>ADD</template>)
          </template>
          <template v-if="sideModals.import.show"> Import VERIFICATION </template>
          <template v-if="sideModals.view.show"> View VERIFICATION </template>
        </template>
        <template v-slot:body>
          <add-edit-verification
            v-if="sideModals.addEdit.show"
            :fields="verification.fields"
            @close="closeSideModal"
            @created="getData()"
          ></add-edit-verification>
          <view-verification
            v-if="sideModals.view.show"
            :fields="verification.fields"
            @close="closeSideModal"
            @created="getData()"
          ></view-verification>
          <ImportVerification
            v-if="sideModals.import.show"
            @close="closeSideModal"
            @imported="getData()"
          ></ImportVerification>
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        (userPermissions.profile_verification.length === 0 ||
          (userPermissions.profile_verification.length > 0 &&
            !userPermissions.profile_verification.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import SideModal from "../components/SideModal.vue";
import AccessDenied from "../components/AccessDenied.vue";
import AddEditVerification from "../components/AddEditVerification.vue";
import ViewVerificationStatus from "../components/ViewVerificationStatus.vue";
import verificationDashboardService from "../services/verificationDashboard.service";

import common from "../mixins/common";
import ImportVerification from "../components/ImportVerification.vue";
import ViewVerification from "../components/ViewVerification.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";

import {fas} from "@fortawesome/free-solid-svg-icons";

library.add(fas)


export default {
  data() {
    return {
      loading: true,
      uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
      sideModals: {
        addEdit: { show: false },
        newRecord: { show: false },
        viewStats: { show: false },
        import: { show: false },
        view: { show: false },
      },
      viewStats: {
        status: false,
        BDTeamProfileName: "",
        data: {},
      },
      verification: {},
      recentVerifications: [],
      verificationStatus: [],
    };
  },
  mixins: [common],
  components: {
    SideModal,
    AddEditVerification,
    ViewVerificationStatus,
    ImportVerification,
    ViewVerification,
    AccessDenied,
    FontAwesomeIcon
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
  methods: {
    closeSideModal() {
      let vm = this;
      vm.$store.dispatch("getUserDetails");
      this.verification = {};
      document.body.classList.remove("lmodal-open");
      this.sideModals.newRecord.show = false;
      this.sideModals.addEdit.show = false;
      this.sideModals.viewStats.show = false;
      this.sideModals.import.show = false;
      this.sideModals.view.show = false;
    },
    async openSideModal(action, Id = "") {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      // vm.closeSideModal();
      if (
        vm.userPermissions &&
        vm.userPermissions.profile_verification &&
        vm.userPermissions.profile_verification.length > 0 &&
        vm.userPermissions.profile_verification.includes("View")
      ) {
        if (
          action === "newRecord" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.addEdit.show = false;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.import.show = false;
          vm.sideModals.newRecord.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
          return;
        } else if (
          action === "createVerification" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.newRecord.show = true;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.import.show = false;
          vm.sideModals.addEdit.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        } else if (
          action === "editVerification" &&
          vm.userPermissions.profile_verification.includes("Update")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.edit(Id);
            const data = response.verifiedProfileData;
            vm.verification = {
              fields: {
                Id: data.Id,
                uprosterProfileId: data.UprosterProfileId,
                responsiblePersonId: data.ResponsiblePersonId,
                documents: [],
                uploadedDocuments: data.documents,
                status: data.Status,
                reason: data.FailureComment,
                UpworksComment: data.UpworksComment,
                ClosingComment: data.ClosingComment,
                requestDate: (data.requestDate) ? vm.$filters.dateFormat(data.requestDate, "YYYY-MM-DD") : '',
                uproster_profile: {
                  ProfileName: data.uproster_profile.ProfileName,
                  deleted_at: data.uproster_profile.deleted_at,
                },
                b_d_team_profile: {
                  Name: data.b_d_team_profile.Name,
                  deleted_at: data.b_d_team_profile.deleted_at,
                },
              },
            };

            vm.sideModals.addEdit.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "viewVerification" &&
          vm.userPermissions.profile_verification.includes("View")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.edit(Id);
            const data = response.verifiedProfileData;
            vm.verification = {
              fields: {
                Id: data.Id,
                uprosterProfileId: data.UprosterProfileId,
                responsiblePersonId: data.ResponsiblePersonId,
                documents: [],
                uploadedDocuments: data.documents,
                status: data.Status,
                reason: data.FailureComment,
                UpworksComment: data.UpworksComment,
                ClosingComment: data.ClosingComment,
                requestDate: (data.requestDate) ? vm.$filters.dateFormat(data.requestDate, "DD/MM/YYYY") : '',
                updated_at: (data.updated_at)? vm.$filters.dateFormat(data.updated_at, "DD/MM/YYYY") : '',
                uproster_profile: {
                  ProfileName: data.uproster_profile.ProfileName,
                  deleted_at: data.uproster_profile.deleted_at,
                },
                b_d_team_profile: {
                  Name: data.b_d_team_profile.Name,
                  deleted_at: data.b_d_team_profile.deleted_at,
                },
              },
            };

            vm.sideModals.view.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "viewStats" &&
          vm.userPermissions.profile_verification.includes("View")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.getStats(Id);
            const data = response;
            vm.viewStats.BDTeamProfileName = data.BDTeamProfileDetail.Name;
            vm.viewStats.data = data;

            vm.sideModals.viewStats.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "importVerification" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.newRecord.show = false;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.addEdit.show = false;
          vm.sideModals.import.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        }
      }
    },
    async getData() {
      let vm = this;
      //await vm.$store.dispatch("getUserDetails");
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await verificationDashboardService.get();
          vm.recentVerifications = response.recentVerifications;
          vm.verificationStatus = response.businessTeamStats;

          var dT = $("#recent-verification").DataTable();
          dT.destroy();

          var dTT = $("#verification-status").DataTable();
          dTT.destroy();

          vm.$nextTick(function () {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            let tableOptions = {
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Recent Verifications " + [year, month, day].join("-"),
                  extension: ".csv",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
              iDisplayLength: 5,
              bLengthChange: false,
              ordering: true,
              columnDefs: [{
                orderable: false,
                targets: "no-sort"
              }],
              info: false,
              /* Disable initial sort */
              aaSorting: [[ 0, "desc" ]],
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
                } else {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
                }
              },
            };

            $("#recent-verification").DataTable(tableOptions);
            tableOptions.buttons[0].filename =
              "Business Team Stats " + [year, month, day].join("-");
            tableOptions.iDisplayLength = 50;
            $("#verification-status").DataTable(tableOptions);

            $(".dt-buttons, .dataTables_filter").hide();
          });
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    async deleteRecentVerification(Id) {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(async(response) => {
        if(response.is_active){
            await vm
              .$swal({
                title: "Delete Verification",
                text: "Are you sure you want to delete this Verification?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#43832a",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              })
              .then(async function (result) {
                if (result.isConfirmed) {
                  try {
                    vm.$store.commit("loadingStatus", true);
                    await vm.$store.dispatch("getUserDetails").then(async(response) => {
                      if(response.is_active){
                        await verificationDashboardService.destroy(Id);
                        vm.getData();
                      }
                    });
                    vm.$store.commit("loadingStatus", false);
                  } catch (error) {
                    const message = vm.errorMessage(error);
                    vm.toastMessage(message, "error");
                    vm.$store.commit("loadingStatus", false);
                  }
                }
              });
         }
     });
    },
    downloadRecentVerificationCsv: async function () {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(response => {
          if(response.is_active) $("#recent-verification_wrapper .buttons-csv").click();
        });
      
    },
    downloadVerificationStatusCsv: async function () {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(response => {
          if(response.is_active) $("#verification-status_wrapper .buttons-csv").click();
        });
      
    },
    getFileTypeIcons(fileType){
      var output = '';  
      if(fileType == 'png' || fileType == 'jpg' || fileType == 'jpeg'){
           output = 'file-image';
        }else if(fileType == 'pdf'){
          output = 'file-pdf';
        }else if(fileType == 'xls' || fileType == 'xlsx'){
          output = 'file-excel';
        }else if(fileType == 'docx'){
          output = 'file-word';
        }else if(fileType == 'csv'){
          output = 'file-csv';
        }
        else if(fileType == 'txt'){
          output = 'file';
        }
     return output;   
    }
  },
  watch: {
    userPermissions: {
      handler() {
        this.getData();
      },
    },
  },
};
</script>
