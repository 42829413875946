<template>
  <!-- side popup modal -->
    <div class="form-field-group view-verification">
      <!--h3 class="form-title">View Verification</h3-->
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="profile">Profile</label>
          <div class="form-field">
              {{verification.uproster_profile.ProfileName}}
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Person Responsible</label>
          <div class="form-field">
            {{verification.b_d_team_profile.Name}}
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Comments From Upwork</label>
          <div class="form-field">
           {{(verification.UpworksComment) ? verification.UpworksComment : 'N/A'}}
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Verification Request Date</label>
              <div class="form-field date-field">
                {{(verification.requestDate) ? verification.requestDate : 'N/A'}}
              </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Verification Closing Date</label>
              <div class="form-field date-field">
                {{(verification.updated_at && verification.status !== 'inProgress') ? verification.updated_at : 'N/A'}}
              </div>
        </div>
      </div>
      <div class="row grid-1" v-if="verification.uploadedDocuments && verification.uploadedDocuments.length > 0">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Uploaded Files</label>
          <div>
            <div class="uploaded-doc">
              <template
                v-for="(document, index) in verification.uploadedDocuments"
                :key="index"
              >
                <div class="uploaded-docoments">
                  <a
                    class="underline-link"
                    :href="`${uploadPath + document.Documents}`"
                    target="_blank"
                    >{{ document.DocumentName }}</a
                  >
                  <a
                    v-if="!['verified', 'failure'].includes(oldStatus)"
                    href="javascript:void(0);"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
              <template
                v-for="(document, index) in verification.documents"
                :key="index"
              >
                <div class="uploaded-docoments">
                  <a
                    class="underline-link"
                    :href="`${document.path}`"
                    target="_blank"
                    >{{ document.name }}</a
                  >
                  <a
                    v-if="!['verified', 'failure'].includes(oldStatus)"
                    href="javascript:void(0);"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Closing Comments</label>
          <div class="form-field">
           {{(verification.ClosingComment) ? verification.ClosingComment : 'N/A'}}
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center radio-fields">
          <label class="form-label" for="type">Status</label>
          <div class="radio-options">
            <ul class="d-flex align-items-center inline-radio">
              <li v-if="verification.status === 'inProgress'">
                <input
                  type="radio"
                  id="progress"
                  name="radio-group"
                  v-model="verification.status"
                  value="inProgress"
                  :disabled="true"
                />
                <label for="progress">In-Progress</label>
              </li>
              <li v-if="verification.status === 'verified'">
                <input
                  type="radio"
                  id="verified"
                  name="radio-group"
                  v-model="verification.status"
                  value="verified"
                  :disabled="true"
                />
                <label for="verified">Verified</label>
              </li>
              <li v-if="verification.status === 'failure'">
                <input
                  type="radio"
                  id="failure"
                  name="radio-group"
                  v-model="verification.status"
                  value="failure"
                  :disabled="true"
                />
                <label for="failure">Failure</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row grid-1" v-if="verification.status === 'failure'">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="proLevel">Failure Reason</label>
          <div class="form-field">
           {{(verification.reason) ? verification.reason : 'N/A'}}
          </div>
        </div>
      </div>
      <div class="row grid-1" v-if="verification.uprosterProfileId">
        <div class="col d-flex  ">
          <router-link
            :to="{
              name: 'ProfileVerificationHistory',
              params: { ID: verification.uprosterProfileId },
            }"
            target="_blank"
            class="verification-history underline-link"
          >
          View Verification History 
          </router-link>
        </div>
      </div>
    </div>
</template>
<script>
import common from "../mixins/common";

export default {
  props: {
    fields: {
      type: Object,
    },
  },
  data: () => ({
    uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
    allowFileTypes: [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "xls",
      "xlsx",
      "docx",
      "csv",
      "txt",
    ],
    profiles: {
      uprosterProfiles: [],
      BDTeamProfiles: [],
    },
    verification: {
      Id: 0,
      submitted: false,
      uprosterProfileId: "",
      responsiblePersonId: "",
      documents: [],
      uploadedDocuments: [],
      reason: "",
      status: "inProgress",
      uproster_profile: {
        ProfileName: ""
      },
      b_d_team_profile: {
        Name: ""
      },
    },
    oldStatus: "",
    removedUploadedDocumentIds: [],
  }),
  computed: {
    totalDocuments() {
      return (
        this.verification.documents.length +
        this.verification.uploadedDocuments.length
      );
    },
  },
  mixins: [common],
  async created() {
    if (this.fields) {
      this.verification = this.fields;
      this.oldStatus = this.fields.status;
    }
  },
};
</script>
<style>
.upload-wrap .uploaded-doc {
  margin-top: 15px;
}
</style>
