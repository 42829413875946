import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class VerificationDashboardService {

  async getProfiles() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/getProfiles`, config);
    return response.data;
  }

  async get() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/list`, config);

    return response.data;
  }

  async create(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}verification/createProfileVerification`, data, config);
    return response.data;
  }

  async update(data, Id) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}verification/updateProfile/${Id}`, data, config);
    return response.data;
  }

  async edit(ID) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/getVerifiedProfileData/${ID}`, config);
    return response.data;
  }

  async destroy(ID) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/deleteVerifiedProfile/${ID}`, config);
    return response.data;
  }

  async getStats(Id) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/getProfilesVerificationStatus/${Id}`, config);

    return response.data;
  }

  async import(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}verification/import`, data, config);

    return response.data;
  }

  async getHistory(Id) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}verification/getProfileVerificationHistory/${Id}`, config);

    return response.data;
  }

}

export default new VerificationDashboardService();